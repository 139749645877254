import React from 'react';
import { bool, func, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withVisibilityLogic } from 'mangools-react-components/src';

import MessageHolder from 'components/messages/MessageHolder';
import SerpIcon from 'components/reusable/SerpIcon';

import SerpFeatureService from 'services/SerpFeatureService';

function SerpFeatureInfoMessage(props) {
    const getFeature = () => {
        if (props.subFeature === true) {
            return SerpFeatureService.getSubFeatureObj(props.featureType);
        } else {
            return SerpFeatureService.getFeatureObj(props.featureType);
        }
    };

    const handleShowSnapshotClick = () => {
        props.onClose();
        props.onShowSnapshot();
    };

    const renderBlogLink = url => (
        <a className="mg-btn is-white is-small" href={url} rel="noopener" target="_blank">
            <FontAwesomeIcon icon={['far', 'question-circle']} />
            More about this feature
        </a>
    );

    const renderSnapshotLink = () => (
        <div className="mg-margin-b-15">
            <button className="mg-btn is-small is-blue" onClick={handleShowSnapshotClick} type="button">
                <FontAwesomeIcon icon="camera" />
                Preview snapshot
            </button>
        </div>
    );

    /* eslint-disable react/no-danger */
    const feature = getFeature();

    return (
        <MessageHolder onClose={props.onClose} classNames="uk-padding-remove">
            <div className="mg-modal-header is-info">
                <SerpIcon icon={feature.iconName} />
            </div>
            <div className="mg-modal-content">
                <h3 className="font-24 uk-text-bold">{feature.name}</h3>
                <p
                    className="mg-margin-t-15 mg-margin-b-15"
                    dangerouslySetInnerHTML={{ __html: feature.description }}
                />
                {feature.showSnapshotPreview === true ? renderSnapshotLink() : null}
                {feature.blogUrl !== null ? renderBlogLink(feature.blogUrl) : null}
            </div>
        </MessageHolder>
    );
    /* eslint-enable react/no-danger */
}

SerpFeatureInfoMessage.propTypes = {
    featureType: string.isRequired,
    onClose: func.isRequired,
    onShowSnapshot: func.isRequired,
    subFeature: bool.isRequired,
};

export default withVisibilityLogic(SerpFeatureInfoMessage);
