import React, { useCallback } from 'react';
import { func, string } from 'prop-types';
import { useTrackMessageVisibilityEffect, withVisibilityLogic, PricingMessageContent } from 'mangools-react-components/src';
import { useSelector, useDispatch } from 'react-redux';

import MessageHolder from 'components/messages/MessageHolder';

import { colorSchemeSelector } from 'selectors/uiSelectors';

import upgradeImg from 'images/upgrade.svg';
import mnglsImage from 'images/mangools-logo-full.svg';
import mnglsImageWhite from 'images/mangools-logo-full-white.svg';

import { gtmTrack } from 'actions/analyticsActions';
import { analyticsEvents, analyticsActions } from 'constants/analytics';

function PricingMessage(props) {
    const dispatch = useDispatch();
    const colorScheme = useSelector(state => colorSchemeSelector(state));

    const onGtmTrack = useCallback(
        payload => {
            dispatch(gtmTrack(payload));
        },
        [dispatch],
    );

    const handleUpgradeClick = () => {
        onGtmTrack({
            action: analyticsActions.CONFIRM,
            event: analyticsEvents.OUT_OF_LIMITS_MESSAGE,
        });
    };

    useTrackMessageVisibilityEffect(analyticsEvents.OUT_OF_LIMITS_MESSAGE, onGtmTrack, { source: 'sch' });

    return (
        <MessageHolder onClose={props.onClose}>
            <PricingMessageContent
                colorScheme={colorScheme}
                userPlanType={props.userPlanType}
                juiceImage={upgradeImg}
                mangoolsLogoImage={mnglsImage}
                mangoolsLogoWhiteImage={mnglsImageWhite}
                queryParams="ref=msg-app-sch"
                onClick={handleUpgradeClick}
            />
        </MessageHolder>
    );
}

PricingMessage.propTypes = {
    onClose: func.isRequired,
    userPlanType: string.isRequired,
};

export default withVisibilityLogic(React.memo(PricingMessage));
