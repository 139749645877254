import 'config/initializer';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from '@/App';

window.APP_ROOT = document.getElementById('app');

if (window.APP_ROOT) {
    const root = createRoot(window.APP_ROOT);
    root.render(<App />);
}
