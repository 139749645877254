import React, { useState } from 'react';
import { bool, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { withVisibilityLogic } from 'mangools-react-components/src';

import ExportTypes from 'constants/ExportTypes';
import MessageHolder from 'components/messages/MessageHolder';

function ExportMessage(props) {
    const [exportType, setExportType] = useState(ExportTypes.SELECTED_METRICS);
    const [includeSerpFeatures, setIncludeSerpFeatures] = useState(false);
    const [includeGlobalMetrics, setIncludeGlobalMetrics] = useState(false);

    const toggleIncludeSerpFeatures = () => {
        setIncludeSerpFeatures(!includeSerpFeatures);
    };

    const toggleIncludeGlobalMetrics = () => {
        setIncludeGlobalMetrics(!includeGlobalMetrics);
    };

    const handleSelectedMetricsClick = () => {
        setExportType(ExportTypes.SELECTED_METRICS);
    };

    const handleAllMetricsClick = () => {
        setExportType(ExportTypes.ALL_METRICS);
    };

    const handleExportClick = e => {
        e.preventDefault();

        props.onExportResults({
            exportType,
            includeSerpFeatures,
            includeGlobalMetrics,
        });
    };

    const handleFeaturesKeyPress = e => {
        e.preventDefault();

        if (e.key === 'Enter') {
            toggleIncludeSerpFeatures();
        }
    };

    const handleGlobalMetricsKeyPress = e => {
        e.preventDefault();

        if (e.key === 'Enter') {
            toggleIncludeGlobalMetrics();
        }
    };

    const renderExportButtonBody = () => {
        if (props.exporting) {
            return (
                <span className="mg-preloader-inline">
                    <span />
                    <span />
                    <span />
                </span>
            );
        } else {
            return 'Download CSV';
        }
    };

    const checkboxLabelClasses = checked =>
        classnames({
            'cursor-pointer': true,
            'font-14': true,
            'is-active': checked,
            'mg-input-container': true,
            'is-icon': true,
            'is-small': true,
            'mg-margin-b-15': true,
            'uk-display-block': true,
        });

    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    return (
        <MessageHolder onClose={props.onClose} classNames="uk-padding-remove fadeInDown animated-once">
            <div className="mg-modal-header">
                <FontAwesomeIcon icon="download" />
            </div>
            <div className="mg-modal-content">
                <h3 className="font-24 uk-text-bold">Choose export type</h3>
                <h4 className="font-14 uk-text-left color-grey mg-margin-b-10">Export metrics</h4>
                <div className="mg-btn-group is-small mg-margin-b-30">
                    <button
                        className={classnames('mg-btn is-small uk-width-1-2', {
                            'is-blue': exportType === ExportTypes.SELECTED_METRICS,
                            'is-white': exportType !== ExportTypes.SELECTED_METRICS,
                        })}
                        onClick={handleSelectedMetricsClick}
                        type="button"
                    >
                        Selected metrics
                    </button>
                    <button
                        className={classnames('mg-btn is-small uk-width-1-2', {
                            'is-blue': exportType === ExportTypes.ALL_METRICS,
                            'is-white': exportType !== ExportTypes.ALL_METRICS,
                        })}
                        onClick={handleAllMetricsClick}
                        type="button"
                    >
                        All metrics
                    </button>
                    <div className="uk-clearfix" />
                </div>
                <h4 className="font-14 uk-text-left color-grey mg-margin-b-10">Export feature options</h4>
                <label
                    htmlFor="serp_features"
                    className={checkboxLabelClasses(includeSerpFeatures)}
                    onKeyPress={handleFeaturesKeyPress}
                    tabIndex={0}
                >
                    <div className="mg-input-icon">
                        <input
                            type="checkbox"
                            name="serp_features"
                            id="serp_features"
                            value={includeSerpFeatures}
                            checked={includeSerpFeatures}
                            onChange={toggleIncludeSerpFeatures}
                            tabIndex="-1"
                        />
                    </div>
                    <div className="mg-input is-small font-14 uk-text-left" style={{ userSelect: 'none' }}>
                        Include SERP features (map pack, etc.)
                    </div>
                </label>
                <label
                    htmlFor="GlobalMetrics"
                    className={checkboxLabelClasses(includeGlobalMetrics)}
                    onKeyPress={handleGlobalMetricsKeyPress}
                    tabIndex={0}
                >
                    <div className="mg-input-icon">
                        <input
                            type="checkbox"
                            name="GlobalMetrics"
                            id="GlobalMetrics"
                            value={includeGlobalMetrics}
                            checked={includeGlobalMetrics}
                            onChange={toggleIncludeGlobalMetrics}
                            tabIndex="-1"
                        />
                    </div>
                    <div className="mg-input is-small font-14 uk-text-left" style={{ userSelect: 'none' }}>
                        Include global SERP metrics (SEO difficulty, etc.)
                    </div>
                </label>
                <button
                    className="mg-btn is-green mg-margin-t-15 uk-width-1-1"
                    disabled={props.exporting}
                    onClick={handleExportClick}
                    type="button"
                >
                    {renderExportButtonBody()}
                </button>
            </div>
        </MessageHolder>
    );
    /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-enable jsx-a11y/no-noninteractive-tabindex */
}

ExportMessage.propTypes = {
    exporting: bool.isRequired,
    onClose: func.isRequired,
    onExportResults: func.isRequired,
};

export default withVisibilityLogic(React.memo(ExportMessage));
